import React, { useEffect, useState } from 'react'
import {  useParams } from 'react-router-dom'
import { client } from '../../client'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'


const CareerDetails = () => {
  const [singleBlogPost, setSingleBlogPost] = useState({});
  const [ isLoading, setIsLoading ] = useState(true);
  let formCode = ` <script src="https://cdn02.jotfor.ms/static/prototype.forms.js?3.3.43416" type="text/javascript"></script>
  <script src="https://cdn03.jotfor.ms/static/jotform.forms.js?3.3.43416" type="text/javascript"></script>
  <script defer src="https://cdnjs.cloudflare.com/ajax/libs/punycode/1.4.1/punycode.js"></script>
  <script src="https://cdn01.jotfor.ms/js/vendor/jquery-1.8.0.min.js?v=3.3.43416" type="text/javascript"></script>
  <script defer src="https://cdn02.jotfor.ms/js/vendor/maskedinput.min.js?v=3.3.43416" type="text/javascript"></script>
  <script defer src="https://cdn03.jotfor.ms/js/vendor/jquery.maskedinput.min.js?v=3.3.43416" type="text/javascript"></script>
  <script src="https://cdn01.jotfor.ms/js/vendor/imageinfo.js?v=3.3.43416" type="text/javascript"></script>
  <script src="https://cdn02.jotfor.ms/file-uploader/fileuploader.js?v=3.3.43416"></script>
  <script src="https://cdn03.jotfor.ms/js/payments/validategateways.js?v=3.3.43416" type="text/javascript"></script>
  <script type="text/javascript">	JotForm.newDefaultTheme = true;
    JotForm.extendsNewTheme = false;
    JotForm.singleProduct = false;
    JotForm.newPaymentUIForNewCreatedForms = true;
    JotForm.newPaymentUI = true;
    JotForm.clearFieldOnHide="disable";
    JotForm.submitError="jumpToFirstError";
  
    JotForm.init(function(){
    /*INIT-START*/
  if (window.JotForm && JotForm.accessible) $('input_5').setAttribute('tabindex',0);
        JotForm.setPhoneMaskingValidator( 'input_7_full', '\u0028\u0023\u0023\u0023\u0029 \u0023\u0023\u0023\u002d\u0023\u0023\u0023\u0023' );
  if (window.JotForm && JotForm.accessible) $('input_12').setAttribute('tabindex',0);
        JotForm.alterTexts(undefined);
        setTimeout(function() {
            JotForm.initMultipleUploads();
        }, 2);
    /*INIT-END*/
    });
  
     JotForm.prepareCalculationsOnTheFly([null,{"name":"heading","qid":"1","text":"Apply Job Form","type":"control_head"},{"name":"submit2","qid":"2","text":"Submit","type":"control_button"},null,null,{"description":"","name":"yourName","qid":"5","subLabel":"","text":"Your name","type":"control_textbox"},{"description":"","name":"email","qid":"6","subLabel":"","text":"Email","type":"control_email"},{"description":"","name":"phoneNumber","qid":"7","text":"Phone Number","type":"control_phone"},{"description":"","name":"fileUpload","qid":"8","subLabel":"Accepted file types: docx, pdf, txt, Max. file size: 32 MB.","text":"Cover Letter","type":"control_fileupload"},{"description":"","name":"fileUpload9","qid":"9","subLabel":"Accepted file types: docx, pdf, txt, Max. file size: 32 MB.","text":"CV\u002FResume","type":"control_fileupload"},null,null,{"description":"","name":"jobtitle","qid":"12","subLabel":"","text":"Job Title","type":"control_textbox"}]);
     setTimeout(function() {
  JotForm.paymentExtrasOnTheFly([null,{"name":"heading","qid":"1","text":"Apply Job Form","type":"control_head"},{"name":"submit2","qid":"2","text":"Submit","type":"control_button"},null,null,{"description":"","name":"yourName","qid":"5","subLabel":"","text":"Your name","type":"control_textbox"},{"description":"","name":"email","qid":"6","subLabel":"","text":"Email","type":"control_email"},{"description":"","name":"phoneNumber","qid":"7","text":"Phone Number","type":"control_phone"},{"description":"","name":"fileUpload","qid":"8","subLabel":"Accepted file types: docx, pdf, txt, Max. file size: 32 MB.","text":"Cover Letter","type":"control_fileupload"},{"description":"","name":"fileUpload9","qid":"9","subLabel":"Accepted file types: docx, pdf, txt, Max. file size: 32 MB.","text":"CV\u002FResume","type":"control_fileupload"},null,null,{"description":"","name":"jobtitle","qid":"12","subLabel":"","text":"Job Title","type":"control_textbox"}]);}, 20); 
  </script>
  <style type="text/css">@media print{.form-section{display:inline!important}.form-pagebreak{display:none!important}.form-section-closed{height:auto!important}.page-section{position:initial!important}}</style>
  <link type="text/css" rel="stylesheet" href="https://cdn01.jotfor.ms/themes/CSS/5e6b428acc8c4e222d1beb91.css?v=3.3.43416"/>
  <link type="text/css" rel="stylesheet" href="https://cdn02.jotfor.ms/css/styles/payment/payment_styles.css?3.3.43416" />
  <link type="text/css" rel="stylesheet" href="https://cdn03.jotfor.ms/css/styles/payment/payment_feature.css?3.3.43416" />
  <link type="text/css" rel="stylesheet" href="https://cdn01.jotfor.ms/stylebuilder/donationBox.css?v=3.3.43416">
  <form class="jotform-form" action="https://submit.jotform.com/submit/231972853685066" method="post" enctype="multipart/form-data" name="form_231972853685066" id="231972853685066" accept-charset="utf-8" autocomplete="on"><input type="hidden" name="formID" value="231972853685066" /><input type="hidden" id="JWTContainer" value="" /><input type="hidden" id="cardinalOrderNumber" value="" />
    <div role="main" class="form-all">
      <ul class="form-section page-section">
        <li class="form-line jf-required" data-type="control_textbox" id="id_5"><label class="form-label form-label-top form-label-auto" id="label_5" for="input_5"> Your name<span class="form-required">*</span> </label>
          <div id="cid_5" class="form-input-wide jf-required" data-layout="half"> <input type="text" id="input_5" name="q5_yourName" data-type="input-textbox" class="form-textbox validate[required]" data-defaultvalue="" style="width:310px" size="310" value="" data-component="textbox" aria-labelledby="label_5" required="" /> </div>
        </li>
        <li class="form-line jf-required" data-type="control_email" id="id_6"><label class="form-label form-label-top form-label-auto" id="label_6" for="input_6"> Email<span class="form-required">*</span> </label>
          <div id="cid_6" class="form-input-wide jf-required" data-layout="half"> <input type="email" id="input_6" name="q6_email" class="form-textbox validate[required, Email]" data-defaultvalue="" style="width:310px" size="310" value="" data-component="email" aria-labelledby="label_6" required="" /> </div>
        </li>
        <li class="form-line jf-required" data-type="control_phone" id="id_7"><label class="form-label form-label-top form-label-auto" id="label_7" for="input_7_full"> Phone Number<span class="form-required">*</span> </label>
          <div id="cid_7" class="form-input-wide jf-required" data-layout="half"> <span class="form-sub-label-container" style="vertical-align:top"><input type="tel" id="input_7_full" name="q7_phoneNumber[full]" data-type="mask-number" class="mask-phone-number form-textbox validate[required, Fill Mask]" data-defaultvalue="" autoComplete="section-input_7 tel-national" style="width:310px" data-masked="true" value="" placeholder="(000) 000-0000" data-component="phone" aria-labelledby="label_7" required="" /></span> </div>
        </li>
        <li class="form-line jf-required" data-type="control_fileupload" id="id_8"><label class="form-label form-label-top form-label-auto" id="label_8" for="input_8"> Cover Letter<span class="form-required">*</span> </label>
          <div id="cid_8" class="form-input-wide jf-required" data-layout="full">
            <div class="jfQuestion-fields" data-wrapper-react="true">
              <div class="jfField isFilled">
                <div class="jfUpload-wrapper">
                  <div class="jfUpload-container">
                    <div class="jfUpload-button-container">
                      <div class="jfUpload-button" aria-hidden="true" tabindex="0" style="display:none" data-version="v2">Browse Files<div class="jfUpload-heading forDesktop">Drag and drop files here</div>
                        <div class="jfUpload-heading forMobile">Choose a file</div>
                      </div>
                    </div>
                  </div>
                  <div class="jfUpload-files-container">
                    <div class="validate[multipleUpload]"><input type="file" id="input_8" name="q8_fileUpload[]" multiple="" class="form-upload-multiple validate[required]" data-imagevalidate="yes" data-file-accept="pdf, doc, docx, txt" data-file-maxsize="32000" data-file-minsize="0" data-file-limit="1" data-component="fileupload" required="" aria-label="Browse Files" /></div>
                  </div>
                </div><span class="form-sub-label-container" style="vertical-align:top"><label class="form-sub-label" for="input_8" id="sublabel_input_8" style="min-height:13px" aria-hidden="false">Accepted file types: docx, pdf, txt, Max. file size: 32 MB.</label></span>
              </div><span style="display:none" class="cancelText">Cancel</span><span style="display:none" class="ofText">of</span>
            </div>
          </div>
        </li>
        <li class="form-line jf-required" data-type="control_fileupload" id="id_9"><label class="form-label form-label-top form-label-auto" id="label_9" for="input_9"> CV/Resume<span class="form-required">*</span> </label>
          <div id="cid_9" class="form-input-wide jf-required" data-layout="full">
            <div class="jfQuestion-fields" data-wrapper-react="true">
              <div class="jfField isFilled">
                <div class="jfUpload-wrapper">
                  <div class="jfUpload-container">
                    <div class="jfUpload-button-container">
                      <div class="jfUpload-button" aria-hidden="true" tabindex="0" style="display:none" data-version="v2">Browse Files<div class="jfUpload-heading forDesktop">Drag and drop files here</div>
                        <div class="jfUpload-heading forMobile">Choose a file</div>
                      </div>
                    </div>
                  </div>
                  <div class="jfUpload-files-container">
                    <div class="validate[multipleUpload]"><input type="file" id="input_9" name="q9_fileUpload9[]" multiple="" class="form-upload-multiple validate[required]" data-imagevalidate="yes" data-file-accept="pdf, doc, docx, txt" data-file-maxsize="32000" data-file-minsize="0" data-file-limit="1" data-component="fileupload" required="" aria-label="Browse Files" /></div>
                  </div>
                </div><span class="form-sub-label-container" style="vertical-align:top"><label class="form-sub-label" for="input_9" id="sublabel_input_9" style="min-height:13px" aria-hidden="false">Accepted file types: docx, pdf, txt, Max. file size: 32 MB.</label></span>
              </div><span style="display:none" class="cancelText">Cancel</span><span style="display:none" class="ofText">of</span>
            </div>
          </div>
        </li>
        <li class="form-line always-hidden" data-type="control_textbox" id="id_12"><label class="form-label form-label-top form-label-auto" id="label_12" for="input_12"> Job Title </label>
          <div id="cid_12" class="form-input-wide always-hidden" data-layout="half"> <input type="text" id="input_12" name="q12_jobtitle" data-type="input-textbox" class="form-textbox" data-defaultvalue="" style="width:310px" size="310" value="jobTitleHeading" data-component="textbox" aria-labelledby="label_12" /> </div>
        </li>
        <li class="form-line" data-type="control_button" id="id_2">
          <div id="cid_2" class="form-input-wide" data-layout="full">
            <div data-align="auto" class="form-buttons-wrapper form-buttons-auto   jsTest-button-wrapperField"><button id="input_2" type="submit" class="form-submit-button submit-button jf-form-buttons jsTest-submitField" data-component="button" data-content="">Apply Now</button></div>
          </div>
        </li>
        <li style="display:none">Should be Empty: <input type="text" name="website" value="" /></li>
      </ul>
    </div>
    <script>
      JotForm.showJotFormPowered = "new_footer";
    </script>
    <script>
      JotForm.poweredByText = "Powered by Jotform";
    </script><input type="hidden" class="simple_spc" id="simple_spc" name="simple_spc" value="231972853685066" />
    <script type="text/javascript">
      var all_spc = document.querySelectorAll("form[id='231972853685066'] .si" + "mple" + "_spc");
      for (var i = 0; i < all_spc.length; i++)
      {
        all_spc[i].value = "231972853685066-231972853685066";
      }
    </script>
    
  </form><script type="text/javascript">JotForm.ownerView=true;</script><script src="https://cdn.jotfor.ms//js/vendor/smoothscroll.min.js?v=3.3.43416"></script>
  <script src="https://cdn.jotfor.ms//js/errorNavigation.js?v=3.3.43416"></script>
  <script type="text/javascript">JotForm.isNewSACL=true;</script> `;

  const { id } = useParams()
  //console.log(id)

  useEffect(() => {
    const getEntryById = async () => {
      try {
        await client.getEntry(id).then((entries) => {
          setSingleBlogPost(entries)
        })
        setIsLoading(false);
      } catch (error) {
        console.log(error)
      }
    };
    getEntryById()
  }, [id])


  return (
    <>
        <section className='h-[400px] bg-secondary'>
        <div className="container mx-auto h-full flex justify-center items-center">
            <h1 className='text-5xl uppercase text-white font-black'>{singleBlogPost?.fields?.title} - {singleBlogPost?.fields?.location}
</h1>
        </div>
        </section>

        <section className='py-10'>
  <div className="container mx-auto">
  


<div className="post-description">
<div className="btn mb-10">Apply for this job</div>
<div className="">
<ReactMarkdown>{singleBlogPost?.fields?.content}</ReactMarkdown>

</div>

       </div>
  </div>
</section>

 

<section class="bg body-font relative">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-12">
      <h2 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Apply now.
</h2>
     
    </div>
    <div class="lg:w-1/2 md:w-2/3 mx-auto">
      <div class="flex flex-wrap -m-2">      
      {!isLoading && ( 
        <div dangerouslySetInnerHTML={{ __html: formCode.replace("jobTitleHeading", singleBlogPost?.fields?.title+" - "+singleBlogPost?.fields?.location) }} />  
      )}         
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default CareerDetails