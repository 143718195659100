import React, { useState, useEffect, useCallback } from 'react'
import { client } from "../../client";
import GetInTouch from "components/GetInTouch";
import heroimg from '../../assets/images/why-banner.jpg';

const Why = () => {
  const [Why, setWhy] = useState([])

  const cleanWhy = useCallback((rawData) => {
    const cleanBG = rawData.map((home) => {
        const {sys, fields} = home
        const {id} = sys
        const title = fields.title
        const subHeading = fields.subHeading
        const sequenceNumber = fields.sequenceNumber
        const whyImage = fields.whyImage.fields.file.url
		const position = fields.position
        const updatedwhy = { title, subHeading, sequenceNumber, whyImage, position }
        return updatedwhy
    })
    setWhy(cleanBG)
}, [])

const getwhy = useCallback( async () => {
  //setIsTestimonials(true)
  try{
    const whyresponse = await client.getEntries({ content_type: 'why' })
    const responseData = whyresponse.items
    console.log(responseData)

    if(responseData){
      cleanWhy(responseData)
      }else{
        setWhy([])
      }

  }
  catch(error){
    //console.log(error)
    //setIsTestimonials(false)
  }

}, [cleanWhy])

useEffect (() => {
  getwhy()
}, [getwhy])


  return (
    <>
      <section className="relative before:absolute before:bg-black before:opacity-50 before:w-full before:h-full before:z-10">
        <div className="h-[841px] bg-cover bg-no-repeat bg-center flex items-center"
        style={{ backgroundImage: `url(${heroimg})`, }} >
              <div className="container mx-auto relative z-20">
                  <h2 className='text-5xl uppercase text-white font-bold mb-12'>WHY POST FRAME</h2>
                  <p className='max-w-xl uppercase text-4xl text-white font-bold relative block after:absolute after:left-0 after:bottom-0 after:h-[11px] after:bg-tertiary after:w-full after:max-w-[208px] pb-8'>Nulla vitae elit libero, a pharetra augue.</p>
              </div>
        </div>
      </section>

      <section className="why-grid-container">

      {Why.map((item) => {
          const { title, subHeading, sequenceNumber, whyImage, position } = item
          return (
            <div className='why-grid flex flex-wrap items-center my-10 md:my-32 lg:my-52'>
          <div className='w-full lg:w-7/12'><img src={whyImage} /></div>
          <div className='w-full lg:w-5/12 flex justify-center'>
            <div className='lg:max-w-md p-5 md:p-12'>
              <h2 className='text-3xl md:text-5xl font-bold uppercase text-secondary mb-8'>{title}</h2>
              <p className='text-xl text-[#6f6f6f]'>{subHeading}</p>
            </div>
          </div>
        </div>
          )

      }
      
      )}

      </section>

      <GetInTouch />
    </>
  )
}

export default Why