import React from 'react'
import { Link } from 'react-router-dom'

import { FaFacebookF, FaInstagram, FaArrowRight } from "react-icons/fa";

const Footer = () => {
  return (
    <>
    <footer className='bg-secondary py-12 lg:py-24'>
      <div className="container mx-auto">
          <div className="md:grid md:grid-cols-12 md:gap-x-6 lg:gap-x-[30px] md:pb-12">
              <div className="col-span-3">
                <address className='text-white not-italic'>
                272179 TWP RD 241B<br /> Rocky View County, AB
                </address>
                <div className='text-white py-6 leading-7'>
                  <p>OFFICE: 000 111 0000</p>
                  <p>FAX: 111 000 1111</p>
                  <p><Link to="#" className='text-primary'>info@domainname.com</Link></p>
                </div>
              </div>

              <div className="footer-menu col-span-3 pb-6 md:pb-0">
                  <h2 className='text-white uppercase font-bold mb-4'>Menu</h2>
                  <ul>
                    <li><Link to="#">Buildings</Link></li>
                    <li><Link to="#">Inspiration</Link></li>
                    <li><Link to="#">Process</Link></li>
                    <li><Link to="#">Why post Frame</Link></li>
                  </ul>
              </div>

              <div className="col-span-3 pb-6 md:pb-0">
                  <h2 className='text-white uppercase font-bold mb-4'>Follow us</h2>
                  <div className='flex'>
                  <Link to=""><FaFacebookF className='text-xl text-white' /></Link>
                  <Link to=""><FaInstagram className='text-xl text-white ml-3' /></Link>
                  </div>
              </div>

              <div className="col-span-3">
                  <h2 className='text-white uppercase font-bold mb-4'>SUBSCRIBE AND STAY TUNED!</h2>
                  <div className='relative'>
                    <input type="email" placeholder="email" className='bg-transparent border-b border-[#787271] text-white outline-none w-full' />
                    <input type="submit" value="s" className='text-[0px] cursor-pointer w-4 h-4 absolute right-0 top-0 z-10' />
                    <FaArrowRight className='text-primary absolute right-0 top-0' />
                  </div>
              </div>
          </div>
      </div>

      <div className="container mx-auto">
          <div className="md:flex md:justify-between md:border-t border-[#787271] pt-8 text-white text-sm">
              <div className="">© 2023 Remuda. All rights reserved.</div>
              <div className="">
                <Link to="#">Privacy Policy</Link>  | <Link to="#">Terms of Use</Link>
              </div>
          </div>

      </div>
    </footer>
    </>
  )
}

export default Footer