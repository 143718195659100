import React, { useState, useEffect, useCallback } from 'react'
import { client } from "../../client";
import { Link, useSearchParams, useLocation } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const Contact = () => {
  const [contactContent, setContactContent] = useState([{contentTop:"",contentFormCode:"",contentFooterContent:""}]);
  const [ isLoading, setIsLoading ] = useState(true);
  const queryParameters = new URLSearchParams(window.location.search)
  const success = queryParameters.get("success")
  const location = useLocation();
  
  const cleanUpContactContent = useCallback((rawData) => {
    const cleanContact = rawData.map((contact) => {
      //alert('hi');
        const {fields} = contact
        // //const {id} = sys
        const contentTop = fields.content;
        const contentFormCode = fields.formCode;
        const contentFooterContent = fields.footerContent2;
        const updatedContent = { contentTop, contentFormCode, contentFooterContent }
        return updatedContent
    })
    setContactContent(cleanContact);
}, [])

const getContactContent = useCallback( async () => {
  //setIsBannerLoading(true)
  try{
    const contactresponse = await client.getEntries({ content_type: 'contact' })
    const responseData = contactresponse.items
    
    if(responseData){
      cleanUpContactContent(responseData);
      
      }else{
        setContactContent([])
      }
      setIsLoading(false);
    //setIsBannerLoading(false)
  }
  catch(error){
    //console.log(error)
    //setIsBannerLoading(false)
  }

}, [cleanUpContactContent])

useEffect (() => {
  getContactContent()
}, [getContactContent, location])


  return (
    <>
    <section className='h-[550px] bg-secondary'>
        <div className="container mx-auto h-full flex justify-center items-center">
           <div className="text-center">
           <h1 className='text-5xl uppercase text-white font-black mb-10'>Contact</h1>
           {!isLoading && (
            <p class=" leading-relaxed text-base text-white">
            <ReactMarkdown rehypePlugins={[rehypeRaw]} children={contactContent[0].contentTop} />
            </p>
           )}
           </div>
        </div>
        
    </section>


     

<section class="text-gray-600 body-font relative">
  <div class="container px-5 py-24 mx-auto">
    
    <div class="lg:w-1/2 md:w-2/3 mx-auto">
      <div class="flex flex-wrap -m-2">
      
      {!success   
        ? <div dangerouslySetInnerHTML={{ __html: contactContent[0].contentFormCode }} /> 
        : <div class="submittedForm">Thank you. Your message submitted.</div>
      }

      {!isLoading && (  
        <ReactMarkdown rehypePlugins={[rehypeRaw]} children={contactContent[0].contentFooterContent} />
       
      )}
        
      </div>
    </div>
  </div>
</section>
    
    </>
  )
}

export default Contact