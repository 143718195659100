//import Carousel from "components/Carousel/Carousel";
import React, { useState, useEffect, useCallback } from 'react'
import { client } from "../../client";
//import heroimg from '../../assets/images/banner-home.jpg';

import img01 from '../../assets/images/hot-air-balloons.jpg';
import img02 from '../../assets/images/cottages-in-the-middle-of-beach-753626.jpg';
import img03 from '../../assets/images/nature-forest-trees-park-38136.jpg';
import leadbg from '../../assets/images/lead-text.png';
import { Link } from "react-router-dom";
import CaseStudiesSlider from "components/CaseStudiesSlider";
import Testimonial from "components/Testimonial";
import GetInTouch from "components/GetInTouch";

const Home = () => {
    //const [isbannerloading, setIsBannerLoading] = useState(false)
    const [BannerImages, setBannerImage] = useState([])

  const cleanUpHomeBanner = useCallback((rawData) => {
    const cleanBG = rawData.map((home) => {
        const {fields} = home
        //const {id} = sys
        const homeBg = fields.bannerImage.fields.file.url
        const updatedBG = { homeBg }
        return updatedBG
    })
    setBannerImage(cleanBG)
}, [])

const gethomebanner = useCallback( async () => {
  //setIsBannerLoading(true)
  try{
    const bannerresponse = await client.getEntries({ content_type: 'homepage' })
    const responseData = bannerresponse.items
    //console.log(responseData)

    if(responseData){
      cleanUpHomeBanner(responseData)
      }else{
        setBannerImage([])
      }

    //setIsBannerLoading(false)
  }
  catch(error){
    //console.log(error)
    //setIsBannerLoading(false)
  }

}, [cleanUpHomeBanner])

useEffect (() => {
  gethomebanner()
}, [gethomebanner])
console.log(BannerImages)
return (
<div>
<section className="relative before:absolute before:bg-gradient-to-t before:from-transparent before:via-transparent before:to-black before:opacity-60 before:w-full before:h-full before:z-10">    
      {BannerImages.map((item) => {
          const {homeBg} = item
          return (
            
            <div className="h-screen relative bg-cover bg-no-repeat bg-center flex justify-center items-center"
            style={{ backgroundImage: `url(${homeBg})`, }} >
                <div className='relative z-20 px-4'>
                    <h2 className='text-3xl lg:text-[4rem] leading-normal uppercase text-white font-black text-center mb-5'>YOU'LL LOVE HOW WE <br /> BUILD BUILDINGS.</h2>
                    <p className='text-lg lg:text-2xl text-white text-center'>We're known for the quality of our post <br /> frame buildings. And for being good listeners.</p>
                </div>
            </div>
          )

      }
      
      )}
  </section>

  <section className='bg-secondary py-24 lg:py-56' style={{ backgroundImage: `url(${leadbg})`, }}>
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12 lg:gap-50">
          <div className="lg:col-span-6">
              <h2 className='text-5xl text-[#f3efea] block pb-8 font-bold relative uppercase after:absolute after:left-0 after:bottom-0 after:h-[11px] after:bg-[#f3efea] after:w-full after:max-w-[208px] after:opacity-10 mb-12 lg:mb-0'>
              BUILDINGS FOR FARMS, BUSINESSES, AND ACREAGES
              </h2>
          </div>
          <div className="lg:col-span-6">
            <p className='text-xl text-[#f3efea] font-medium'>With each build, we strive for perfection and to give the customer exactly what they want. They appreciate those details, and they share stories about what it was like working with us. We love when that happens, because a company like Remuda is built on a solid reputation.</p>
          </div>
        </div>
        </div>    
  </section>


  <section>



<div className="container mx-auto pb-20 lg:pb-44 mt-20">
  <div className="grid grid-cols-1 lg:grid-cols-12 lg:gap-x-6 gap-y-16 lg:gap-y-32">
    <div className="lg:col-span-5">
    <div className="stacked-cta">
        <Link to="#">
        <figure className="h-[300px] lg:h-[600px] bg-cover overflow-hidden relative bg-no-repeat" style={{ backgroundImage: `url(${img01})`, }}></figure>
        <div className="bg-tertiary mx-auto w-[90%] max-w-[571px] p-6 lg:p-14 -mt-28 relative z-10">
          <h4 className="text-3xl lg:text-4xl	text-secondary uppercase font-bold mb-3">01.Homes</h4>
          <p className="text-secondary text-lg lg:text-xl">Protect vehicles and equipment from the elements with an acreage shop or garage.</p>
        </div>
        </Link>
      </div>
    </div>
    <div className="lg:col-span-7">
    <div className="stacked-cta">
        <Link to="#">
        <figure className="h-[300px] lg:h-[600px] bg-cover overflow-hidden relative bg-no-repeat" style={{ backgroundImage: `url(${img02})`, }}></figure>
        <div className="bg-tertiary mx-auto w-[90%] max-w-lg p-6 lg:p-14 -mt-28 relative z-10">
          <h4 className="text-3xl lg:text-4xl	text-secondary uppercase font-bold mb-3">02.Acreages</h4>
          <p className="text-secondary text-xl">Protect vehicles and equipment from the elements with an acreage shop or garage.</p>
        </div>
        </Link>
      </div>
    </div>
    <div className="lg:col-span-12">
    <div className="stacked-cta">
        <Link to="#">
        <figure className="h-[300px] lg:h-[600px] bg-cover overflow-hidden relative bg-no-repeat" style={{ backgroundImage: `url(${img03})`, }}></figure>
        <div className="bg-tertiary mx-auto w-[90%] max-w-lg p-6 lg:p-14 -mt-28 lg:ml-32 relative z-10">
          <h4 className="text-3xl lg:text-4xl	text-secondary uppercase font-bold mb-3">03.Acreages</h4>
          <p>Protect vehicles and equipment from the elements with an acreage shop or garage.</p>
        </div>
        </Link>
      </div>
    </div>

    <div className="lg:col-span-7">
      <div className="stacked-cta">
        <Link to="#">
        <figure className="h-[300px] lg:h-[600px] bg-cover overflow-hidden relative bg-no-repeat" style={{ backgroundImage: `url(${img01})`, }}></figure>
        <div className="bg-tertiary mx-auto w-[90%] max-w-lg p-6 lg:p-14 -mt-28 lg:ml-32 relative z-10">
          <h4 className="text-3xl lg:text-4xl	text-secondary uppercase font-bold mb-3">04.Acreages</h4>
          <p>Protect vehicles and equipment from the elements with an acreage shop or garage.</p>
        </div>
        </Link>
      </div>
    </div>

    <div className="lg:col-span-5">
      <div className="stacked-cta">
        <Link to="#">
        <figure className="h-[300px] lg:h-[600px] bg-cover overflow-hidden relative bg-no-repeat" style={{ backgroundImage: `url(${img02})`, }}></figure>
        <div className="bg-tertiary mx-auto w-[90%] max-w-lg p-6 lg:p-14 -mt-28 relative z-10">
          <h4 className="text-3xl lg:text-4xl	text-secondary uppercase font-bold mb-3">05.Acreages</h4>
          <p>Protect vehicles and equipment from the elements with an acreage shop or garage.</p>
        </div>
        </Link>
      </div>
    </div>
     
  </div>
</div>
</section>



<CaseStudiesSlider />
<Testimonial />
<GetInTouch />


    {/* <Carousel /> */}
    </div>
)
}

export default Home